<template>
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">История</p>
                    <div>
                        <el-button type="success" size="medium" plain>
                            {{ $t("message.close") }}
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Наименование:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Samsung RB30N4020B1/WT
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex">
                                <span class="d-block mr-3"> Товар: </span>
                                <div class="d-flex align-center font-medium">
                                    Кондиционер
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Серийный номер:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    CXZC3454CX
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Класс энергопотребления:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    A+
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Дата продажи:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    12.12.2020
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Гарантия: </span>
                                <div class="d-flex align-center font-medium">
                                    3 года
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Дата истечения срока гарантии:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    12.12.2020
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">Адрес: </span>
                                <div class="d-flex align-center font-medium">
                                    Ташкент
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">Клиент: </span>
                                <div class="d-flex align-center font-medium">
                                    Ахмад ака
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">Телефон: </span>
                                <div class="d-flex align-center font-medium">
                                    998990090099
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div
                    style="background: rgb(225, 243, 216)"
                    class="
                        timeline-items__right
                        rounded-sm
                        w-100
                        p-4
                        mt-3
                        bg-oq
                    "
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Дата: </span>
                                <div class="d-flex align-center font-medium">
                                    12.05.2019 15:45:
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Оператор: </span>
                                <div class="d-flex align-center font-medium">
                                    Владимир Зеленский
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Тип обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Ремонт
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Мастер
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Комментарий: </span>
                                <div class="d-flex align-center font-medium">
                                    Test
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус заявки:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Не завершён
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3 pt-4">
                                    Аудио звонок:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    <audio controls>
                                        <source
                                            src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                                            type="audio/mp3"
                                        />
                                    </audio>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div
                    style="background: rgb(250, 236, 216)"
                    class="
                        timeline-items__right
                        rounded-sm
                        w-100
                        p-4
                        mt-3
                        bg-oq
                    "
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Дата: </span>
                                <div class="d-flex align-center font-medium">
                                    12.05.2019 15:45:
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Оператор: </span>
                                <div class="d-flex align-center font-medium">
                                    Владимир Зеленский
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Тип обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Ремонт
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Мастер
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Комментарий: </span>
                                <div class="d-flex align-center font-medium">
                                    Test
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус заявки:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Не завершён
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3 pt-4">
                                    Аудио звонок:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    <audio controls>
                                        <source
                                            src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                                            type="audio/mp3"
                                        />
                                    </audio>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div
                    style="background: rgb(253, 226, 226)"
                    class="
                        timeline-items__right
                        rounded-sm
                        w-100
                        p-4
                        mt-3
                        bg-oq
                    "
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Дата: </span>
                                <div class="d-flex align-center font-medium">
                                    12.05.2019 15:45:
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Оператор: </span>
                                <div class="d-flex align-center font-medium">
                                    Владимир Зеленский
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Тип обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Ремонт
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус обслуживания:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Мастер
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Комментарий: </span>
                                <div class="d-flex align-center font-medium">
                                    Test
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Статус заявки:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Не завершён
                                </div>
                            </div>
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3 pt-4">
                                    Аудио звонок:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    <audio controls>
                                        <source
                                            src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                                            type="audio/mp3"
                                        />
                                    </audio>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawerShow: false,
            form: {},
        };
    },
};
</script>
