<template>
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Реализован</p>
                    <div>
                        <el-button type="success" size="medium" plain>
                            Отправить мастерам
                        </el-button>
                        <el-button type="success" size="medium" plain>
                            Завершить заявку
                        </el-button>

                        <el-button
                            type="warning"
                            size="medium"
                            @click="close()"
                            plain
                        >
                            {{ $t("message.close") }}
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Наименование:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    Samsung RB30N4020B1/WT
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex">
                                <span class="d-block mr-3"> Товар: </span>
                                <div class="d-flex align-center font-medium">
                                    Кондиционер
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Серийный номер:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    CXZC3454CX
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Класс энергопотребления:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    A+
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Дата продажи:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    12.12.2020
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3"> Гарантия: </span>
                                <div class="d-flex align-center font-medium">
                                    3 года
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">
                                    Дата истечения срока гарантии:
                                </span>
                                <div class="d-flex align-center font-medium">
                                    12.12.2020
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="6">
                            <div class="text-descr d-flex mb-4">
                                <span class="d-block mr-3">Клиент: </span>
                                <div class="d-flex align-center font-medium">
                                    Ахмад ака
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-form ref="form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <div class="app-form__group mb-4 ikklik">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Адрес:"
                                    >
                                        <el-select
                                            class="w-100"
                                            size="medium"
                                            v-model="form.region"
                                            filterable
                                            placeholder="Адрес:"
                                        >
                                            <el-option
                                                label="Zone one"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Zone two"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-button
                                        @click="centerDialogVisible = true"
                                        size="small"
                                        icon="el-icon-plus"
                                    >
                                    </el-button></div
                            ></el-col>

                            <el-col :span="8">
                                <div class="app-form__group mb-4 ikklik">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Телефон:"
                                    >
                                        <el-select
                                            class="w-100"
                                            size="medium"
                                            v-model="form.regiona"
                                            filterable
                                            placeholder="Телефон:"
                                        >
                                            <el-option
                                                label="Zone one"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Zone two"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-button
                                        @click="addtel = true"
                                        size="small"
                                        icon="el-icon-plus"
                                    >
                                    </el-button>
                                </div>
                            </el-col>

                            <el-col :span="8" class="text-right pt-5">
                                <el-button
                                    @click="drawerHistory = true"
                                    type="success"
                                    size="mini"
                                >
                                    История
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="Ремонт " name="first">
                            <el-form ref="form" :model="form">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <el-form-item label="Причина">
                                            <el-select
                                                v-model="form.region"
                                                placeholder="Причина"
                                                filterable
                                                multiple
                                                class="w-100"
                                            >
                                                <el-option
                                                    label="Zone one"
                                                    value="shanghai"
                                                ></el-option>
                                                <el-option
                                                    label="Zone two"
                                                    value="beijing"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item
                                            label="Предварительная дата:"
                                        >
                                            <el-date-picker
                                                type="date"
                                                placeholder="Предварительная дата"
                                                v-model="form.date1"
                                                style="width: 100%"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="Комментарий...">
                                            <el-input
                                                type="textarea"
                                                v-model="form.desc"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <div class="itme-cause">
                                            <el-divider content-position="left">
                                                Причина 1: A1, A2, A3
                                            </el-divider>
                                            <el-card class="box-card mb-4">
                                                <div class="prchinna Success">
                                                    A1:
                                                </div>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                            </el-card>
                                            <el-card class="box-card mb-4">
                                                <div class="prchinna Warning">
                                                    A1:
                                                </div>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                            </el-card>
                                            <el-card class="box-card mb-4">
                                                <div class="prchinna Danger">
                                                    A1:
                                                </div>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                            </el-card>
                                        </div>
                                        <div class="itme-cause">
                                            <el-divider content-position="left">
                                                Причина 1: A1, A2, A3
                                            </el-divider>
                                            <el-card class="box-card mb-4">
                                                <div class="prchinna Danger">
                                                    A1:
                                                </div>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                            </el-card>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="Монтаж" name="second">
                            <el-form ref="form" :model="form">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <el-form-item
                                            label="Предварительная дата монтажа:"
                                        >
                                            <el-date-picker
                                                type="date"
                                                placeholder="Предварительная дата монтажа"
                                                v-model="form.date1"
                                                style="width: 100%"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="Комментарий...">
                                            <el-input
                                                type="textarea"
                                                v-model="form.desc"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-dialog
                title="Новый адрес"
                :visible.sync="centerDialogVisible"
                width="30%"
                :append-to-body="true"
                center
            >
                <span>
                    <el-form ref="form" :model="form">
                        <el-form-item label="Новый адрес">
                            <el-input
                                type="textarea"
                                v-model="form.desc"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <span
                        slot="footer"
                        class="dialog-footer mt-5 text-center d-block"
                    >
                        <el-button @click="centerDialogVisible = false">
                            Закрыть
                        </el-button>
                        <el-button
                            type="primary"
                            @click="centerDialogVisible = false"
                        >
                            Сохранить
                        </el-button>
                    </span>
                </span>
            </el-dialog>

            <el-dialog
                title="Новый телефонный номер"
                :visible.sync="addtel"
                width="30%"
                :append-to-body="true"
                center
            >
                <span>
                    <el-form ref="form" :model="form">
                        <el-form-item label="Новый телефонный номер">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-form>
                    <span
                        slot="footer"
                        class="dialog-footer mt-5 text-center d-block"
                    >
                        <el-button @click="centerDialogVisible = false">
                            Закрыть
                        </el-button>
                        <el-button
                            type="primary"
                            @click="centerDialogVisible = false"
                        >
                            Сохранить
                        </el-button>
                    </span>
                </span>
            </el-dialog>
        </div>

        <div class="app-modal app-modal__full mg scrol">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerHistory"
                size="70%"
                :append-to-body="true"
                custom-class="scrol"
            >
                <div>
                    <History> </History>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import History from "./history.vue";
export default {
    components: {
        History,
    },
    data() {
        return {
            drawerHistory: false,
            activeName: "first",
            centerDialogVisible: false,
            addtel: false,
            form: {},
        };
    },
};
</script>
